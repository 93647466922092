<template>
    <div class="grid upcoming-events">
        <div class="col two-thirds">
            <h2>{{ $t("events_all_upcoming") }}</h2>
            <EventList>
                <template v-slot:no-events>
                    <SystemMessage
                        v-if="true"
                        type="events"
                        :title="$t('events_no_events_title')"
                        :text="$t('events_no_events_text')"
                    />
                </template>
            </EventList>
        </div>
        <div class="col third"></div>
    </div>
</template>

<script>
import EventList from "@/views/events/EventList";

export default {
    name: "EventOverviewTab",
    components: {
        EventList,
    },
    mounted() {
        this.$emit("tab-changed", "event-overview");
    },
};
</script>

<style lang="scss" scoped>
.upcoming-events {
    padding-top: 20px;
}
</style>
